import React, {
	useEffect,
	useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import tenantsService from 'src/services/tenantService';
import axios from 'axios';

function Auth({ children }) {
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(true);
	const pathname = window.location.pathname;
	const urlParams = new URLSearchParams(window.location.search);
	const initAuth = async () => {
		authService.setAxiosInterceptors({
			onLogout: () => dispatch(logout())
		});

		authService.handleAuthentication();

		if (authService.isAuthenticated()) {
			const user = await authService.loginUsingToken();

			await dispatch(setUserData(user));
		}

		setLoading(false);
	};

	const authG7 = async (token) => {
		authService.loginG7(token);
			setLoading(false);
	}
	const silentLogin = async () => {
		authService.loginUsingCredentials(
			process.env.REACT_APP_G7_USER,
			process.env.REACT_APP_G7_PWD
			).finally(()=>{
				setLoading(false);
		})
	}

	useEffect(() => {
		if(pathname && pathname.includes('g7')){
			// if(pathname.includes('ticket')){
			// 	silentLogin();
			// }else{
				const token = urlParams.get('token');
				if(token){
					authG7(token);
				}
			// }
		}else{
			initAuth();
		}
	}, [dispatch,pathname]);

	if (isLoading) {
		return <SplashScreen />;
	}

	return children;
}

Auth.propTypes = {
	children: PropTypes.any
};

export default Auth;
