import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  fade,
  makeStyles,
  colors
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 5,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 25,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase',
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: fade(theme.palette.primary.main, 0.08)
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: fade(theme.palette.secondary.main, 0.08)
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.08)
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: fade(theme.palette.success.main, 0.08)
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: fade(theme.palette.warning.main, 0.08)
  },
  red: {
    backgroundColor: fade(colors.red[500], 0.08),
    color: colors.red[500]
  },
  green: {
    backgroundColor: fade(colors.green[500], 0.08),
    color: colors.green[500]
  }, 
  orange: {
    backgroundColor: fade(colors.orange[500], 0.08),
    color: colors.orange[500]
  },
  blue: {
    backgroundColor: fade(colors.blue[500], 0.08),
    color: colors.blue[500]
  },
  yellow: {
    backgroundColor: fade(colors.yellow[500], 0.08),
    color: colors.yellow[500]
  },
  white: {
    backgroundColor: fade('#fff', 0.08),
    color: '#fff'
  },
  
  grey: {
    backgroundColor: colors.grey[500],
    color: '#fff'    
  }
  ,
  blue2: {
    backgroundColor: colors.blue[500],
    color: '#fff'    
  },
  green2: {
    backgroundColor: colors.green[500],
    color: '#fff'    
  },
  light2: {
    backgroundColor: colors.lightBlue[500],
    color: '#fff'    
  },
  warning2: {
    backgroundColor: colors.orange[500],
    color: '#000'    
  },
  test: {
    backgroundColor: '#000',
    color: '#fff'
  }
}));

function Label({
  className,
  color,
  children,
  style,
  ...rest
}) {
  const classes = useStyles();

  return (
    <span
      className={
        clsx(classes.root, {
          [classes[color]]: color
        }, className)
      }
      style={style}
      {...rest}
    >
      {children}
    </span>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success','green', 'red', 'yellow', 'orange','blue','white','grey','test'])
};

Label.defaultProps = {
  className: '',
  color: 'secondary'
};

export default Label;
