import { makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import layoutService from 'src/services/layoutService';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import Sidebar from './Sidebar';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		display: 'flex',
		overflow: 'hidden',
		position: 'relative'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

function TaskView({ children }) {
	const classes = useStyles();
	const pageRef = useRef(null);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const {t} = useTranslation();

	const pages = [{ title: t(TRANSLATE.SUPPORT) }, { title: t(TRANSLATE.TASK) }];
	layoutService.setBreadcrumbs(pages);

	const handleSidebar = res => {
		setSidebarOpen(res);
	};
	
	return (
		<Page className={classes.root} title={t(TRANSLATE.TASKS_LIST)} ref={pageRef}>
			<Sidebar
				containerRef={pageRef}
				handleSidebar={handleSidebar}
				sidebarOpen={sidebarOpen}
			/>
			{children}
		</Page>
	);
}

export default TaskView;
