import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  Trash2 as DeleteIcon,
  Download as DownloadIcon
} from 'react-feather';
import { getNotifications } from 'src/actions/notificationsActions';
import { getDownload } from 'src/actions/downloadActions';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Label from 'src/components/Label';
import { useTranslation } from 'react-i18next';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
	badge: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginTop: 10,
		marginRight: 5
	},
	popover: {
		width: 700,
		padding: theme.spacing(2)
	},
	item: {
		width: 120,
    textAlign: 'center'
	},
  stop: {
		textDecoration: 'underline',
		cursor: 'pointer'
	},
  space: {
		width: 100,
    textAlign: 'center'
	},
  dl: {
		textDecoration: 'underline',
		cursor: 'pointer',
    marginTop: 15
	}
}));

function Notifications() {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notifications.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
	const download = useSelector((state) => state.download.download);
  const [isOpenNotifier, setOpenNotifier] = useState(false);
  const [user] = useState(useSelector(state => state.account).user);
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation();
	const handleOpenNotifier = () => {
    dispatch(getDownload(user?.id));
		setOpenNotifier(true);
	};
	
	const handleCloseNotifier = () => {
		setOpenNotifier(false);
	};

  // useEffect(() => {
  //   dispatch(getNotifications());
  // }, [dispatch]);

	const dlRemove = (id) => {
    setLoading(true);
		axios
			.delete('/api/v1/downloads/' + id)
      .then(() => { 
        dispatch(getDownload(user?.id));
      }).finally(() => {
        setLoading(false);
        enqueueSnackbar(t(TRANSLATE.DELETED), {
        variant: 'success'
      })});
	};

  const dlStop = (id) => {
    setLoading(true);
		axios
			.delete('/api/v1/downloads/stop/' + id)
      .then(() => { 
        dispatch(getDownload(user?.id));
        setOpenNotifier(false);
        setOpenNotifier(true);
      }).finally(() => {
        setLoading(false);
        enqueueSnackbar(t(TRANSLATE.STOPPED), {
        variant: 'success'
      })});
	};

  const allDlRemove = () => {
    setLoading(true);
		axios
			.delete('/api/v1/downloads')
      .then(() => { 
        dispatch(getDownload(user?.id));
      }).finally(() => {
        setLoading(false);
        enqueueSnackbar(t(TRANSLATE.COMPLETED), {
        variant: 'success'
      })}
      );
	};

  const downloadFile = (id, fileName) => {
    setLoading(true);
    try {
      enqueueSnackbar('Downloading...', { variant: 'info' });
        axios({
         url: '/api/v1/downloads/' + id + '/file',
         method: 'GET',
         responseType: 'blob',
       }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName + '.xlsx');
        document.body.appendChild(link);
         link.click();
      }).finally(() => {
        setLoading(false);
        enqueueSnackbar(t(TRANSLATE.COMPLETED), {
          variant: 'success'
        })
      }
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(t(TRANSLATE.ERROR), {
         variant: 'error'
       });
      }
  }

  return (
    <>
			<Tooltip title={t(TRANSLATE.NOTIFICATIONS)}>
				{download?.length === 0 && notifications?.length === 0 ? (
					<Badge
						color="secondary"
						classes={{ badge: classes.badge }}
					>
						<IconButton
							color="inherit"
							onClick={handleOpenNotifier}
							ref={ref}
						>
							<SvgIcon fontSize="small">
								<BellIcon />
							</SvgIcon>
						</IconButton>
					</Badge>
				) : (
					<Badge
						color="secondary"
						variant="dot"
						classes={{ badge: classes.badge }}
					>
						<IconButton
							color="inherit"
							onClick={handleOpenNotifier}
							ref={ref}
						>
							<SvgIcon fontSize="small">
								<BellIcon />
							</SvgIcon>
						</IconButton>
					</Badge>
				)}
			</Tooltip>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleCloseNotifier}
				open={isOpenNotifier}
			>
				{download?.length === 0 && notifications?.length === 0 && (
						<Typography
              variant="body2"
              color="textPrimary"
            >
              {t(TRANSLATE.NO_NOTIFY)}
            </Typography>
				)}
        {download?.length > 0 && ( 
          <>
            <List
              className={classes.list}
              disablePadding
            >
              <Box display="flex">
                <Typography
                  variant="h4"
                  color="textPrimary"
                >
                  {t(TRANSLATE.HISTORY_DOWNLOAD_)}
                </Typography>
                  {loading && (
                    <Box ml={3}>
                      <CircularProgress size={20} />
                    </Box>
                  )}
              </Box>
              <ListItem divider >
                    <ListItemText
                      className={classes.item}
                      secondary={t(TRANSLATE.NAME)}
                    />
                    <ListItemText
                      className={classes.item}
                      secondary={t(TRANSLATE.STATUS)}
                    />
                    <ListItemText
                      className={classes.item}
                      secondary={t(TRANSLATE.START)}
                    />
                    <ListItemText
                      className={classes.item}
                      secondary={t(TRANSLATE.PROGRESS)}
                    />
                    <ListItemText
                      className={classes.item}
                      secondary={t(TRANSLATE.END)}
                    />
                    <ListItemText
                      className={classes.space}
                      secondary={t(TRANSLATE.ACTIONS)}
                    /> 
                  </ListItem>
              {download.map((dl) => {
                return (
                  <ListItem
                    className={classes.popover_}
                    divider
                    key={dl.id}
                  >
                    <ListItemText
                      className={classes.item}
                      primary={dl?.description ? dl?.description : dl?.id}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                    />
                    <ListItemText
                      className={classes.item}
                      secondary={
                        dl?.status?.description === 'Completo' ? ( 
                          <Label color={'success'}>
                            {t(TRANSLATE.COMPLETED)}
                          </Label>
                        ) : (
                          <Label color={'warning'}>
                            {t(TRANSLATE.SCHEDULED_)}
                          </Label>
                        )
                      }
                    />
                    <ListItemText
                      className={classes.item}
                      secondary={moment(dl?.start).format('DD/MM HH:mm')}
                    />
                    <ListItemText
                      className={classes.item}
                      secondary={dl?.current + '/' + dl?.count}
                    />
                    {dl?.status?.description === 'Completo' && (
                    <ListItemText
                      className={classes.item}
                      secondary={moment(dl?.end).format('DD/MM HH:mm')}
                    /> 
                    )}
                    {dl?.status?.description === 'Completo' ? (
                      <Tooltip title="DOWNLOAD">
                        <IconButton
                          disabled={loading}
                          edge="end"
                          onClick={() => downloadFile(dl?.id, dl?.description)}
                        >
                          <SvgIcon fontSize="small">
                            <DownloadIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <ListItemText
                        className={classes.space}
                      />   
                    )}
                    {dl?.status?.description !== 'Schedulato' 
                    && dl?.status?.description !== 'In esecuzione' && (
                      <Tooltip title={t(TRANSLATE.DELETE)}>
                        <IconButton
                          edge="end"
                          disabled={loading}
                          onClick={() => dlRemove(dl?.id)}
                        >
                          <SvgIcon fontSize="small">
                            <DeleteIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    )}
                    {dl?.status?.description !== 'Completo'
                    && dl?.status?.description !== 'Errore' && (
                      <Tooltip title="Stop">
                        <Typography
                          className={classes.stop}
                          disabled={loading}
                          edge="end"
                          onClick={() => dlStop(dl?.id)}
                        >
                            STOP
                        </Typography>
                      </Tooltip>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {download?.length > 0 && (
            <Typography
                onClick={() => !loading ? allDlRemove() : null}
                className={classes.dl}
              >
                {t(TRANSLATE.REMOVE_ALL_DOWNLOAD)}
            </Typography>
        )} 
        {notifications?.length > 0 && (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to="#"
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="#"
              >
                Mark all as read
              </Button>
            </Box>
          </>
				)}
      </Popover>
    </>
  );
}

export default Notifications;
