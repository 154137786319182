/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
	Avatar,
	Box,
	Divider,
	Drawer,
	Hidden,
	Link,
	List,
	ListSubheader,
	makeStyles,
	Typography
} from '@material-ui/core';
import {
	ElectricCar as ElectricCarIcon,
	People as PeopleIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { MessageCircle as MessageCircleIcon, Flag } from 'react-feather';
import Logo from 'src/components/Logo';
import userInfo from 'src/services/userInfo';
import NavItem from './NavItem';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import { useTranslation } from 'react-i18next';
import {  
	Settings as SettingsIcon
} from 'react-feather';
import { TRANSL } from 'src/constants/constant';

function renderNavItems({ items, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;	

	if (item.items) {
		
		let total = 0;
		for (let subitem of item.items) {
			if (userInfo.can(subitem.acl)) {
				total++;
			}
		}

		if (total > 0) {
			const open = matchPath(pathname, {
				path: item.href,
				exact: false
			});

			if (userInfo.can(item.acl)) {
				acc.push(
					<NavItem
						depth={depth}
						icon={item.icon}
						key={key}
						info={item.info}
						open={Boolean(open)}
						title={item.title}
					>
						{renderNavItems({
							depth: depth + 1,
							pathname,
							items: item.items
						})}
					</NavItem>
				);
			}
		}
	} else {
		if (userInfo.can(item.acl)) {
			acc.push(
				<NavItem
					depth={depth}
					href={item.href}
					icon={item.icon}
					key={key}
					info={item.info}
					title={item.title}
				/>
			);
		}
	}

	return acc;
}

const useStyles = makeStyles(theme => ({
	mobileDrawer: {
		width: 256
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64,
		background: theme.palette.secondary.main
	}
	,
	menuList: {
		backgroundColor: theme.palette.background.main
	},
	header: {
		background: theme.palette.primary.main
	},
	headerTitle: {
		color: theme.palette.primary.contrastText
	},
	logo: {
		maxWidth: 200,
		maxHeight: 60
	}
}));

function NavBar({ openMobile, onMobileClose }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const location = useLocation();
	const { user } = useSelector(state => state.account);
	const navConfig = [
		{
			items: [
				{
					icon: ElectricCarIcon,
					title: TRANSLATE.ASSET,
					acl: 'telemago.portal.menu.dashboard.general',
					href: '/app/overview/electric',
					items:[
						{
							title: TRANSLATE.LIST,
							acl: 'telemago.portal.menu.aa.devices',
							href: '/app/overview/electric'
						}/*,
						{
							title: TRANSLATE.SPARE_PARTS,
							acl: 'telemago.portal.menu.aa.devices',
							href: '/app/overview/spareparts'
						}*/
					]
				},
				{
					icon: PeopleIcon,
					title: TRANSLATE.CRM,
					acl: 'telemago.portal.menu.dashboard.general',
					href: '/app/overview/businessPartners/importer',
					items:[						
					{
						icon: PeopleIcon,
						title: TRANSLATE.BUSINESS_PARTNER,
						acl: 'telemago.portal.menu.dashboard.general',
						href: '/app/overview/businessPartners/importer',
						items:[						
							{
								title: TRANSLATE.IMPORTERS,
								acl: 'telemago.portal.menu.aa.businessPartner',
								href: '/app/overview/businessPartners/importer',
							},
							{
								title: TRANSLATE.DEALERS,
								acl: 'telemago.portal.menu.aa.businessPartner',
								href: '/app/overview/businessPartners/dealer'
							},
							{
								title: TRANSLATE.WORKSHOPS,
								acl: 'telemago.portal.menu.aa.businessPartner',
								href: '/app/overview/businessPartners/workshop',
							},
							{
								title: TRANSLATE.CUSTOMERS,
								acl: 'telemago.portal.menu.aa.businessPartner',
								href: '/app/overview/businessPartners/customer',
							},
							{
								title: TRANSLATE.SUPPLIERS,
								acl: 'telemago.portal.menu.aa.businessPartner',
								href: '/app/overview/businessPartners/supplier',
							}
						]
					},
					{
						title: TRANSLATE.SALES_ORDER_STATUS,
						acl: 'telemago.portal.menu.aa.devices',
						href: '/app/overview/salesorder'
					},					
					{
						icon: Flag,
						title: TRANSLATE.MAINTENANCE,
						acl: 'telemago.portal.menu.dashboard.general',
						href: '/app/overview/maintenance',
						items:[
							{
								title: TRANSLATE.LIST,
								acl: 'telemago.portal.menu.aa.maintenances',
								href: '/app/overview/maintenance'
							},
							{
								title: TRANSLATE.ACTIVITIES,
								acl: 'telemago.portal.menu.aa.maintenances',
								href: '/app/overview/activities'
							}
						]
					},
					{
						icon: MessageCircleIcon,
						title: t(TRANSLATE.SERVICE_ORDERS),
						acl: 'telemago.portal.menu.ticket',
						href: '/app/tickets',
						items:[	
							{
								title: TRANSL.TICKET_DASHBOARD,
								acl: 'telemago.portal.menu.ticket',
								href: '/app/ticketsDashboard',
							},				
							/*{
								title: TRANSLATE.Tickets_general,
								acl: 'telemago.portal.menu.ticket',
								href: '/app/tickets',
							},*/
							{
								title: TRANSLATE.SERVICE_REQUESTS,
								acl: 'telemago.portal.menu.ticket',
								href: '/app/ticketsType/PRE_CLAIM',
							},
							{
								title: TRANSLATE.TICKETS,
								acl: 'telemago.portal.menu.ticket',
								href: '/app/ticketsType/WARRANTY_MAINTENANCE_2',
							}
						]
					}, 
					]
				},
				{
					icon: SettingsIcon,
					title: TRANSLATE.Configuration,
					acl: 'telemago.portal.menu.dashboard.admin',
					href: '/app/admin/users',
					items:[						
						{
							title: TRANSLATE.Users,
							acl: 'telemago.portal.menu.dashboard.admin',
							href: '/app/overview/admin/users',
						} 
					]
				},
				/*
				{
					icon: HelpCircleIcon,
					title: TRANSLATE.Warranty,
					acl: 'telemago.portal.menu.dashboard.general',
					href: '/app/overview/warranty'
				},
				{
					icon: MessageCircleIcon,
					title: t(TRANSLATE.TASK),
					acl: 'telemago.portal.menu.task',
					href: '/app/tasks'
				},
				{
					icon: MessageCircleIcon,
					title: 'TODO',
					acl: 'telemago.portal.menu.todo',
					href: '/app/todo',
				},
				*/
			]
		}
	];

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column" className={classes.menuList}>
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box className={classes.header} pt={2}>
						<Box
							p={2}
							display="flex"
							justifyContent="center"
							height={70}
							alignItems="center"
						>
							<RouterLink to="/">
								<Logo size="small" className={classes.logo} />
							</RouterLink>
						</Box>

						<Box p={2} >
							<Box display="flex" justifyContent="center">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user?.avatar}
								/>
							</Box>

							<Box mt={2} textAlign="center">
								<Typography variant="h5" className={classes.headerTitle}>
									{`${user?.firstName} ${user?.lastName}`}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Hidden>
				<Hidden lgDown>
					<Box p={2}>
						<Box display="flex" justifyContent="center">
							<RouterLink to="/app/account">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user?.avatar}
								/>
							</RouterLink>
						</Box>
						<Box mt={2} textAlign="center">
							<Link
								component={RouterLink}
								to="/app/account"
								variant="h5"
								color="textPrimary"
								underline="none"
							>
								{`${user?.name}`}
							</Link>
						</Box>
					</Box>
				</Hidden>

				<Divider />

				<Box p={2}>
					{navConfig.map(config =>
						userInfo.can(config.acl) ? (
							<List
								key={config.subheader}
								subheader={
									<ListSubheader disableGutters disableSticky>
										{config.subheader}
									</ListSubheader>
								}
							>
								{renderNavItems({
									items: config.items,
									pathname: location.pathname
								})}
							</List>
						) : null
					)}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
