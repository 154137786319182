import React, {
  createContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from 'src/submodules/commons-misc/constant';
import { getStoredSettings, storeSettings } from 'src/utils/settings';
import configService from 'src/services/configService';

const SettingsContext = createContext();
const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export function SettingsProvider({ settings, children }) {

  const defaultSettings = {
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.POST.name,
    language: userLocale === 'it-IT' ? userLocale : 'en-EN'
  };

  console.log("USERLOCALE: "+userLocale);

  const [currentSettings, setCurrentSettings] = useState(settings || getStoredSettings() || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  configService.getConfig();

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
