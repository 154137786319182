import axios from 'src/utils/axios';
import { URLS } from 'src/submodules/commons-system/constants/urls';

const BPARTNER_KEY = 'bpartnerId';
class BpartnerService {

	constructor() {}

	load(id) {
		return axios.get(`${URLS.BPARTNERS}/${id}`);
	}

	search(_params) {
		return axios.get(URLS.BPARTNERS, { params: _params });
	}

	setBpartner(id) {
		localStorage.setItem(BPARTNER_KEY, id);
	}

	getBpartner() {
		return localStorage.getItem(BPARTNER_KEY);
	}

	clearStorage() {
		localStorage.removeItem(BPARTNER_KEY);
		delete axios.defaults.headers.common['X-EP-BPartner'];
	}

	setInterceptor() {
		axios.defaults.headers.common['X-EP-BPartner'] = this.getBpartner();
	}

	 

}

const bpartnerService = new BpartnerService();

export default bpartnerService;
