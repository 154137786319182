import PropTypes from 'prop-types';
function DesktopGuard({ children }) {
  
  return children;
}

DesktopGuard.propTypes = {
  children: PropTypes.any
};

export default DesktopGuard;
