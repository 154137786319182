/* eslint-disable no-use-before-define */
import {
	Box,
	Divider,
	Drawer,
	Hidden,
	List,
	makeStyles,
	Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/utils/axios';
import { URLS } from 'src/submodules/commons-system/constants/urls';
import LabelItem from './LabelItem';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	drawerDesktopRoot: {
		width: 280,
		flexShrink: 0
	},
	drawerDesktopPaper: {
		position: 'relative'
	},
	drawerMobilePaper: {
		position: 'relative',
		width: 280
	},
	drawerMobileBackdrop: {
		position: 'absolute'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	text: {
		fontWeight: 'inherit',
		flexGrow: 1,
		textAlign: 'center'
	}
}));

function Sidebar({ containerRef, handleSidebar, sidebarOpen }) {
	const classes = useStyles();
	const isMountedRef = useIsMountedRef();
	const [isLoading, setIsLoading] = useState(false);
	const [storyBoards, setStoryboards] = useState(null);
	const params = useParams();
	const history = useHistory();
	const { t } = useTranslation();

	const getStoryboards = useCallback(() => {
		if(isMountedRef.current ) {
			setIsLoading(true);
			var url = `${URLS.STORYBOARDS}?status=ACTIVE`;
			axios
				.get(url)
				.then(response => {
					if (isMountedRef.current) {
						setStoryboards(response.data.results);
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [isMountedRef]);

	useEffect(() => {
		getStoryboards();
	}, [getStoryboards]);

	function setFirstItem() {
		let to = '/app/tasks/' + storyBoards?.[0]?.id;
		history.replace(to)
	}

	useEffect(() => {
		if(!params?.storyboardId && storyBoards) 
		 	setFirstItem()
	}, [params, storyBoards])

	const content = (
		<div>
			<Box px={3} py={2}>
				<Typography
					className={classes.text}
					variant="body1"
					color="textPrimary"
				>
					{t(TRANSLATE.WORKFLOW_TYPOLOGY)}
				</Typography>
			</Box>
			<Divider />
			<Box py={2} pr={isLoading ? 0 : 2}>
				<List>
					{isLoading &&
						[1, 2, 3, 4, 5].map(x => <Skeleton key={x} height={50} />)}
					{storyBoards?.map(storyB => (
						<LabelItem key={storyB?.id} label={storyB} />
					))}
				</List>
			</Box>
		</div>
	);

	return (
		<>
			<Hidden smDown>
				<Drawer
					variant="permanent"
					classes={{
						root: classes.drawerDesktopRoot,
						paper: classes.drawerDesktopPaper
					}}
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdUp>
				<Drawer
					variant="temporary"
					open={sidebarOpen}
					onClose={() => handleSidebar(false)}
					classes={{
						paper: classes.drawerMobilePaper
					}}
					style={{ position: 'absolute', zIndex: 1200 }}
					BackdropProps={{
						classes: { root: classes.drawerMobileBackdrop }
					}}
					ModalProps={{ container: () => containerRef.current }}
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

Sidebar.propTypes = {
	containerRef: PropTypes.any.isRequired
};

export default Sidebar;
