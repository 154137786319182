/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import DesktopGuard from './components/Guards/DesktopGuard';
import AuthGuard from './components/Guards/AuthGuard';
import GuestGuard from './components/Guards/GuestGuard';
import AuthLayout from './submodules/commons-auth';
import TaskView from './views/task/TaskView';

const routesConfig = [
	{
		exact: true,
		path: '/',
		component: () => <Redirect to="/app" />
	},
	{
		exact: true,
		path: '/404',
		component: lazy(() => import('src/views/pages/Error404View'))
	},

	{
		exact: true,
		path: '/500',
		component: lazy(() => import('src/views/pages/Error500'))
	}
	,
	{
		path: '/auth',
		guard: GuestGuard,
		layout: AuthLayout,
		routes: [
			{
				exact: true,
				path: '/auth/login',
				component: lazy(() => import('src/submodules/commons-auth/Login'))
			},
			{
				exact: true,
				path: '/auth/login-unprotected',
				component: lazy(() => import('src/submodules/commons-auth/Login'))
			},
			{
				exact: true,
				path: '/auth/registration',
				component: lazy(() => import('src/submodules/commons-auth/Registration'))
			},
			{
				exact: true,
				path: '/auth/complete-registration',
				component: lazy(() => import('src/submodules/commons-auth/CompleteRegistration'))
			},
			{
				exact: true,
				path: '/auth/lostpassword',
				component: lazy(() => import('src/submodules/commons-auth/LostPassword'))
			},
			{
				exact: true,
				path: '/auth/resetpassword',
				component: lazy(() => import('src/submodules/commons-auth/ResetPassword'))
			},
			{
				component: () => <Redirect to="/auth/login" />
			}
		]
	}
	,
	{
		path: '/validation',
		routes: [
			{
				exact: true,
				path: '/Validation/EmailConfirm/:token',
				component: lazy(() => import('src/views/validation/EmailConfirm'))
			},
			{
				component: () => <Redirect to="/404" />
			}
		]
	},{
				path: '/g7',
				guard: DesktopGuard,
				layout: DashboardLayout,
				routes: [
					{
						exact: true,
						path: '/g7/asset/:idDevice',
						component: lazy(() => import('src/views/g7/asset'))
					},
					{
						exact: true,
						path: ['/g7/tickets/:vinId','/g7/ticket/:ticketId','/g7/tickets'],
						component: lazy(() => import('src/views/g7/ticket'))
					}
				]
	},{
		guard: AuthGuard,
		routes: [
			{
				path: '/app',
				guard: DesktopGuard,
				layout: DashboardLayout,
				routes: [
					{
						exact: true,
						path: '/app',
						component: () => <Redirect to="/app/overview/electric" />
					},
					{
						exact: true,
						path: '/app/overview/electric',
						component: lazy(() => import('src/views/overview/electric'))
					},
					{
						exact: true,
						path: '/app/overview/spareparts',
						component: lazy(() => import('src/views/overview/spareparts'))
					},
					{
						exact: true,
						path: '/app/overview/salesorder',
						component: lazy(() => import('src/views/overview/salesorder'))
					},

					{
						exact: true,
						path: '/app/overview/businessPartners/:businessPartnerType',
						component: lazy(() => import('src/views/overview/businessPartner'))
					},

					{
						exact: true,
						path: '/app/overview/businessPartner/:businessPartnerType/:bpId',
						component: lazy(() => import('src/views/overview/businessPartner/Details'))
					},  
					{
						exact: true,
						path: '/app/overview/warranty',
						component: lazy(() => import('src/views/overview/warranty'))
					},
					{
						exact: true,
						path: '/app/overview/electric/:idDevice',
						component: lazy(() => import('src/views/overview/electric/Details'))
					},
					{
						exact: true,
						path: '/app/overview/maintenance',
						component: lazy(() => import('src/views/overview/maintenance'))
					},
					{
						exact: true,
						path: '/app/overview/activities',
						component: lazy(() => import('src/views/overview/maintenance/Activities'))
					},
					{
						exact: true,
						path: '/app/overview/maintenance/:idMaintenance',
						component: lazy(() => import('src/views/overview/maintenance/Details'))
					},
					{
						exact: true,
						path: '/app/tickets',
						component: lazy(() => import('src/views/ticket/List'))
					},
					{
						exact: true,
						path: '/app/ticketsDashboard',
						component: lazy(() => import('src/views/ticket/Dashboard'))
					},
					{
						exact: true,
						path: '/app/ticketsType/:tkT',
						component: lazy(() => import('src/views/ticket/Warranty/List'))
					},
					
					{
						exact: true,
						path: '/app/tickets/:vinId',
						component: lazy(() => import('src/views/ticket/List'))
					},
					{
						exact: true,
						path: '/app/tickets/:idTicket/view',
						component: lazy(() => import('src/views/ticket/Details'))
					},
					{
						exact: true,
						path: '/app/tickets/:ticketId/redirect',
						component: lazy(() => import('src/views/ticket/Redirect'))
					},
					{
						exact: true,
						path: ['/app/ticket/add','/app/ticket/add/:idCustomer'],
						component: lazy(() => import('src/views/ticket/Add'))
					},
					{
						exact: true,
						path: '/app/tickets/:ticketId/edit',
						component: lazy(() => import('src/views/ticket/Add'))
					},
					{
						path: ['/app/tasks/:storyboardId?'],
						layout: TaskView,
						routes: [
							{
								exact: true,
								path: ['/app/tasks/:storyboardId'],
								component: lazy(() => import('src/views/task/TaskView/TaskList'))
							}
						]
					},
					{
						exact: true,
						path: '/app/todo',
						component: lazy(() => import('src/views/task/TODO/List'))
					},
					{
						exact: true,
						path: '/app/account',
						component: lazy(() => import('src/views/pages/AccountView'))
					},
					{
						exact: true,
						path: '/app/overview/admin/users',
						component: lazy(() => import('src/views/overview/configuration/Users'))
					},

					
					{
						component: () => <Redirect to="/404" />
					}
				]
			}
		]
	}
];

const renderRoutes = routes =>
	routes ? (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i) => {
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;

					return (
						<Route
							key={i}
							path={route.path}
							exact={route.exact}
							render={props => (
								<Guard>
									<Layout>
										{route.routes ? (
											renderRoutes(route.routes)
										) : (
											<Component {...props} />
										)}
									</Layout>
								</Guard>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	) : null;

function Routes() {
	return renderRoutes(routesConfig);
}

export default Routes;
